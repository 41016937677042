/*-----------Types of SPONSOR actions--------*/

/*---SAVE----*/
export const SPONSOR_SAVE_SUCCESS = "SPONSOR_SAVE_SUCCESS";
export const SPONSOR_SAVE_FAILED = "SPONSOR_SAVE_FAILED";
export const SPONSOR_IS_SAVING = "SPONSOR_IS_SAVING";
/*---FETCH----*/
export const SPONSOR_FETCH_SUCCESS = "SPONSOR_FETCH_SUCCESS";
export const ONE_SPONSOR_FETCH_SUCCESS = "One_SPONSOR_FETCH_SUCCESS";
export const SPONSOR_FETCH_FAILED = "SPONSOR_FETCH_FAILED";
export const SPONSOR_IS_FETCHING = "SPONSOR_IS_FETCHING";
/*---UPDATE----*/
export const SPONSOR_UPDATE_SUCCESS = "SPONSOR_UPDATE_SUCCESS";
export const SPONSOR_UPDATE_FAILED = "SPONSOR_UPDATE_FAILED";
export const SPONSOR_IS_UPDATING = "SPONSOR_IS_UPDATING";

/*---PATCH----*/
export const SPONSOR_PATCH_SUCCESS = "SPONSOR_PATCH_SUCCESS";
export const SPONSOR_PATCH_FAILED = "SPONSOR_PATCH_FAILED";
export const SPONSOR_IS_PATCHING = "SPONSOR_IS_PATCHING";


/*---DELETE----*/
export const SPONSOR_DELETE_SUCCESS = "SPONSOR_DELETE_SUCCESS";
export const SPONSOR_DELETE_FAILED = "SPONSOR_DELETE_FAILED";
export const SPONSOR_IS_DELETING = "SPONSOR_IS_DELETING";