/*-----------Types of ABOUT actions--------*/

/*---SAVE----*/
export const NEWS_SAVE_SUCCESS = "NEWS_SAVE_SUCCESS";
export const NEWS_SAVE_FAILED = "NEWS_SAVE_FAILED";
export const NEWS_IS_SAVING = "NEWS_IS_SAVING";
/*---FETCH----*/
export const NEWS_FETCH_SUCCESS = "NEWS_FETCH_SUCCESS";
export const ONE_NEWS_FETCH_SUCCESS = "One_NEWS_FETCH_SUCCESS";
export const NEWS_FETCH_FAILED = "NEWS_FETCH_FAILED";
export const NEWS_IS_FETCHING = "NEWS_IS_FETCHING";
/*---UPDATE----*/
export const NEWS_UPDATE_SUCCESS = "NEWS_UPDATE_SUCCESS";
export const NEWS_UPDATE_FAILED = "NEWS_UPDATE_FAILED";
export const NEWS_IS_UPDATING = "NEWS_IS_UPDATING";

/*---PATCH----*/
export const NEWS_PATCH_SUCCESS = "NEWS_PATCH_SUCCESS";
export const NEWS_PATCH_FAILED = "NEWS_PATCH_FAILED";
export const NEWS_IS_PATCHING = "NEWS_IS_PATCHING";


/*---DELETE----*/
export const NEWS_DELETE_SUCCESS = "NEWS_DELETE_SUCCESS";
export const NEWS_DELETE_FAILED = "NEWS_DELETE_FAILED";
export const NEWS_IS_DELETING = "NEWS_IS_DELETING";