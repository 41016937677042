/*-----------Types of THEMES actions--------*/

/*---SAVE----*/
export const THEME_SAVE_SUCCESS = "THEME_SAVE_SUCCESS";
export const THEME_SAVE_FAILED = "THEME_SAVE_FAILED";
export const THEME_IS_SAVING = "THEME_IS_SAVING";
/*---FETCH----*/
export const THEME_FETCH_SUCCESS = "THEME_FETCH_SUCCESS";
export const ONE_THEME_FETCH_SUCCESS = "One_THEME_FETCH_SUCCESS";
export const THEME_FETCH_FAILED = "THEME_FETCH_FAILED";
export const THEME_IS_FETCHING = "THEME_IS_FETCHING";
/*---UPDATE----*/
export const THEME_UPDATE_SUCCESS = "THEME_UPDATE_SUCCESS";
export const THEME_UPDATE_FAILED = "THEME_UPDATE_FAILED";
export const THEME_IS_UPDATING = "THEME_IS_UPDATING";

/*---PATCH----*/
export const THEME_PATCH_SUCCESS = "THEME_PATCH_SUCCESS";
export const THEME_PATCH_FAILED = "THEME_PATCH_FAILED";
export const THEME_IS_PATCHING = "THEME_IS_PATCHING";


/*---DELETE----*/
export const THEME_DELETE_SUCCESS = "THEME_DELETE_SUCCESS";
export const THEME_DELETE_FAILED = "THEME_DELETE_FAILED";
export const THEME_IS_DELETING = "THEME_IS_DELETING";