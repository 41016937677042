/*-----------Types of EVENT actions--------*/

/*---SAVE----*/
export const EVENT_SAVE_SUCCESS = "EVENT_SAVE_SUCCESS";
export const EVENT_SAVE_FAILED = "EVENT_SAVE_FAILED";
export const EVENT_IS_SAVING = "EVENT_IS_SAVING";
/*---FETCH----*/
export const EVENT_FETCH_SUCCESS = "EVENT_FETCH_SUCCESS";
export const EVENT_FETCH_FAILED = "EVENT_FETCH_FAILED";
export const EVENT_IS_FETCHING = "EVENT_IS_FETCHING";

/*---UPDATE----*/
export const EVENT_UPDATE_SUCCESS = "EVENT_UPDATE_SUCCESS";
export const EVENT_UPDATE_FAILED = "EVENT_UPDATE_FAILED";
export const EVENT_IS_UPDATING = "EVENT_IS_UPDATING";

/*---PATCH----*/
export const EVENT_PATCH_SUCCESS = "EVENT_PATCH_SUCCESS";
export const EVENT_PATCH_FAILED = "EVENT_PATCH_FAILED";
export const EVENT_IS_PATCHING = "EVENT_IS_PATCHING";


/*---DELETE----*/
export const EVENT_DELETE_SUCCESS = "EVENT_DELETE_SUCCESS";
export const EVENT_DELETE_FAILED = "EVENT_DELETE_FAILED";
export const EVENT_IS_DELETING = "EVENT_IS_DELETING";